<template>
  <section :class="['news-article-page', dir_ar]" v-if="!loading">
    <div
      class="article-cover"
      :style="{
        backgroundImage: `url(${
          getVNews.image_url != null ? getVNews.image_url : placeholder
        })`,
      }"
      v-if="getVNews.image_url"
    ></div>
    <div
      class="article-cover"
      :style="{ backgroundImage: `url(${placeholder})` }"
      v-else
    ></div>
    <div class="container">
      <div class="row">
        <div class="col-md-9 col-12">
          <div class="date mt-5 mb-2 text-pri">
            {{ formateDateAndTime(getVNews.created_at, 'date') }}

          </div>
          <div class="title">
            <h2 class="fw-bold">
              {{ getVNews.titles[lang] }}
            </h2>
          </div>
          <div
            class="article-text text-desc"
            v-html="getVNews.descriptions[lang]"
          ></div>
        </div>
        <div class="col-md-3 col-12 mt-5">
          <button class="btn-share mx-auto btn-pri" @click="toggleShareBox">
            <i class="fa fa-share-alt"></i>
            {{ $t("shareNews") }}
          </button>
          <h3
            class="header-main d-flex align-items-center mt-5 mb-3"
            v-if="getAllNews && getAllNews.length != 0"
          >
            {{ $t("latest News") }}
            <span class="header-divider d-inline-block mx-1 mt-1"></span>
          </h3>
          <div
            class="row my-2 pointer mb-3"
            v-for="(news, i) in getAllNews.filter(el => el.id != getVNews.id).slice(0, 7)"
            :key="news.id * 4 + i"
            @click="goToNews(news)"
            v-show="getAllNews && getAllNews.length != 0"
          >
            <div class="col-5">
              <img
                :src="news.image_url ? news.image_url||placeholder : placeholder"
                style="aspect-ratio: 1/1; object-fit: cover"
                class="w-100 rounded"
                alt="News Cover"
              />
            </div>
            <div class="col-7">
              <h6>{{ news.title }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LastNewsSection
      :title="$t('related news')"
      desc=" "
      v-if="getAllNews && getAllNews.length> 0"
      :readMoreBtn="false"
      :lastNews="[...getAllNews].reverse().filter(el=>el.id != getVNews.id).slice(0, 3)"
    />
    <transition name="fade">
      <share-box
        v-if="share"
        @close="toggleShareBox"
        :sharing="sharing"
      ></share-box>
    </transition>
  </section>
  <div class="err text-danger text-center py-4" v-else-if="err">
    {{ $t("noNewsFound") }}
  </div>
  <div class="container py-5 my-5" v-else>
    <div class="loader my-5 mx-auto"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LastNewsSection from "../components/LastNews.vue";
import ShareBox from "../components/misc/ShareBox.vue";
export default {
  components: {
    LastNewsSection,
    ShareBox,
  },
  data() {
    return {
      fromatedArticalText: "",
      share: false,
      err: false,
      sharing: {
        url: "",
        title: "",
        description: "",
        quote: "",
        hashtags: "EG_Emabssy,News,New_News",
        twitterUser: "EG_Embassy",
      },
      loading: true,
      relatedNews: [],
    };
  },
  watch: {
    $route(nr) {
      if (nr) {
        this.loadNews();
        this.loading = true;
        this.loadVNews({
          id: nr.params.id,
        })
          .then((res) => {
            this.loading = false;
            this.sharing.url = location.href;
            this.sharing.title = this.getVNews.titles[this.lang];
            this.sharing.description = this.getVNews.descriptions[this.lang];
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    share(nshare) {
      if (nshare) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
    },
  },
  mounted() {
    if (!this.$route.params.id) {
      this.$router.push({
        name: "last-news",
      });
    }
    this.loadNews();
    this.loadVNews({
      id: this.$route.params.id,
    }).then((res) => {
      this.loading = false;
      if (res.status == 200) {
        this.sharing.url = location.href;
        this.sharing.title = this.getVNews.titles[this.lang];
        this.sharing.description = this.getVNews.descriptions[this.lang];
      }
    });
  },
  computed: {
    ...mapGetters(["getVNews", "getNews", "getAllNews"]),
    artical() {
      const artId = this.$route.params.id;
      const artical = this.getAllNews.find((art) => art.id == artId);
      return artical;
    },
    articalText() {
      const artical = this.artical;
      return artical.articalText;
    },
  },
  methods: {
    ...mapActions(["loadVNews", "loadNews", "loadFilterdNews"]),
    toggleShareBox() {
      this.share = !this.share;
    },
    goToNews(news) {
      if (this.$route.name == "NewsArticle") {
        if (this.getVNews.id == news.id) {
          return false;
        } else {
          this.$router.replace({
            name: "NewsArticle",
            params: { id: news.id, title: news.title.split(" ").join("_") },
          });
        }
      } else {
        this.$router.push({
          name: "NewsArticle",
          params: { id: news.id, title: news.title.split(" ").join("_") },
        });
      }
    },
  },
};
</script>

<style>
</style>